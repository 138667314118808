import { Switch } from '@headlessui/react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { JSXElementConstructor } from 'react';
interface Props {
  enabled: boolean;
  EnabledIcon?: JSXElementConstructor<{ className: string }>;
  DisabledIcon?: JSXElementConstructor<{ className: string }>;
  type?: 'positive' | 'negative';
  label?: string;
  onChange?: (val: boolean) => void;
  onSideEffect?: () => void;
  disabledIconClassName?: string;
}

export function Toggle(props: Props) {
  const {
    enabled,
    EnabledIcon,
    DisabledIcon,
    type = 'positive',
    label,
    disabledIconClassName,
    onChange,
    onSideEffect,
  } = props;
  const onChangeCb = (val: boolean) => {
    onSideEffect && onSideEffect();
    onChange && onChange(val);
  };
  const xIcon = <XMarkIcon className="text-primary-600 h-3 w-3" />;
  const checkIcon = <CheckIcon className="text-primary-600 h-3 w-3" />;
  const positive = type === 'positive';
  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        checked={enabled}
        onChange={onChangeCb}
        className={clsx(
          enabled &&
            (positive
              ? 'bg-primary-600 dark:bg-primary-500'
              : 'bg-red-600 dark:bg-red-900'),
          !enabled &&
            (positive
              ? 'bg-gray-300 dark:bg-gray-700'
              : 'dark:bg-dark-border-surface bg-gray-300'),
          'focus:ring-primary-500 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2'
        )}
      >
        <span className="sr-only">Toggle</span>
        <span
          className={clsx(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'dark:ring-dark-border-surface pointer-events-none relative inline-block h-5 w-5 transform rounded-full border-2 shadow ring-0 transition duration-200 ease-in-out',
            positive ? 'bg-white' : 'bg-dark-text'
          )}
        >
          <span
            className={clsx(
              enabled
                ? 'opacity-0 duration-100 ease-out'
                : 'opacity-100 duration-200 ease-in',
              'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
            )}
            aria-hidden="true"
          >
            {DisabledIcon ? (
              <DisabledIcon
                className={`text-primary-600 h-3 w-3 ${disabledIconClassName}`}
              />
            ) : positive ? (
              xIcon
            ) : (
              checkIcon
            )}
          </span>
          <span
            className={clsx(
              enabled
                ? 'opacity-100 duration-200 ease-in'
                : 'opacity-0 duration-100 ease-out',
              'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
            )}
            aria-hidden="true"
          >
            {EnabledIcon ? (
              <EnabledIcon className="text-primary-600 h-3 w-3" />
            ) : positive ? (
              checkIcon
            ) : (
              xIcon
            )}
          </span>
        </span>
      </Switch>
      {label && (
        <span className="dark:text-dark-secondary-text ml-2 text-sm text-gray-500">
          <Switch.Label>{label}</Switch.Label>
        </span>
      )}
    </Switch.Group>
  );
}

export default Toggle;
